import BottomButton from "@/components/BottomButton";
import personalPropertyClause from "@/components/documents/personalPropertyClause";
import personalPropertyDisclaimer from "@/components/documents/personalPropertyDisclaimer";
import personalPropertyNotice from "@/components/documents/personalPropertyNotice";
import { registerComponent } from "@/utils";
import agreement from "@/components/documents/agreement";
import disclosuresafe from "@/components/documents/disclosuresafe";

export const components = registerComponent({
  BottomButton,
  personalPropertyClause,
  personalPropertyDisclaimer,
  personalPropertyNotice,
  agreement,
  disclosuresafe,
});

export default { components };
